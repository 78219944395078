import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { getSelfcareUrl } from "../config/config";

import {
  Grid,
  Box,
  Container,
  Button,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Popover,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import "../assets/stylesheets/landing.css";
import boingoLogo from "../assets/images/Boingo-Logo.svg";
import fiberLogo from "../assets/images/Boingo-Fiber-Logo.svg";
import magnifyLens from "../assets/images/Magnifying-Lens.svg";
import whyBoingoFiberSubHeader from "../assets/images/why-boingo-fiber-subhead.svg";

import signInIcon from "../assets/images/Sign-In-Mobile.svg";
import setUpSubHeader from "../assets/images/setup-in-seconds-savings-subhead.svg";

import tpLinkImg from "../assets/images/TP-Link-Deco-W3600.png";
import amazonEeroImg from "../assets/images/Amazon-Eero-6.png";
import dLinkImg from "../assets/images/D-Link-AX1500.png";
import googleACImg from "../assets/images/Google-AC1200.png";

import worksWithBoingImg from "../assets/images/Works-With-Boingo-Fiber-Sticker.svg";
import horizontalLine from "../assets/images/Footer-Horizontal-Line-Desktop.svg";
import lookForSticker from "../assets/images/Look-For-This-Sticker-With-Arrow.svg";
// Navbar component
const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box>
      <Box className="mobile-nav-container" onClick={handleDrawerToggle}>
        <img src={fiberLogo} />
        <CloseIcon className="close-icon" />
      </Box>

      <Box className="mobile-nav-sub-section">
        <Typography className="lets-start">
          Let&#8217;s start by finding out
          <br /> if Boingo Fiber is available <br /> in your home
        </Typography>
        <Link to="/check-availability" className="avail-btn">
          Check availability
        </Link>
      </Box>
      <Box className="mobile-nav-hr"></Box>
      <Box className="mobile-sign-in-wrapper">
        <a href={getSelfcareUrl(window.location.host)}>
          <img src={signInIcon} />
          Have an account? Log in
        </a>
      </Box>
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        className="app-bar"
        sx={{ backgroundColor: "#fff" }}
      >
        <Toolbar>
          {isMobile ? (
            <>
              {/* Mobile Navigation */}
              <Box
                className="mobile-nav"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item xs={3} sx={{ textAlign: "left" }}>
                    <img
                      src={boingoLogo}
                      className="boingo-logo"
                      alt="Boingo Logo"
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <img src={fiberLogo} className="fiber-logo" />
                  </Grid>

                  <Grid item xs={2} sx={{ textAlign: "right" }}>
                    <MenuIcon
                      color="primary"
                      aria-label="open drawer"
                      onClick={handleDrawerToggle}
                      sx={{ color: "#000", cursor: "pointer" }}
                    />
                  </Grid>
                </Grid>

                <Drawer
                  variant="temporary"
                  open={mobileOpen}
                  anchor="right"
                  onClose={handleDrawerToggle}
                  ModalProps={{ keepMounted: true }}
                >
                  {drawer}
                </Drawer>
              </Box>
            </>
          ) : (
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                flexFlow: "row"
              }}
            >
              <Grid
                sx={{
                  width:"auto",
                  alignSelf:"flex-start"
                }}
              >
                <Grid
                  item
                  sx={{
                    textAlign: "right",
                    width:"300px",
                    display:"flex",
                    justifyContent:"flex-start"
                  }}
                >
                  <img
                    src={boingoLogo}
                    className="boingo-logo"
                    alt="Boingo Logo"
                  />
                </Grid>
                </Grid>
                <Grid
                  sx={{ textAlign: "right" }}
                >
                  <img
                    src={fiberLogo}
                    className="fiber-logo"
                    alt="Fiber Logo"
                  />
                </Grid>
                <Grid sx={{
                  justifyContent: "flex-end",
                  display: "flex"
                }}>
                  <Grid
                    sx={{
                      display: "flex",
                      width:"300px",
                      flexFlow:"row wrap",
                      alignSelf:"flex-end",
                      justifyContent:"flex-end"
                    }}
                  >
                    <Grid
                      item
                      xs={false}
                      sx={{ textAlign: "right" }}
                    >
                      <Link
                        to="/check-availability"
                        className="avail-btn"
                      >
                        Check availability
                      </Link>
                    </Grid>
                    <Grid
                      item
                      xs={false}
                      sx={{ textAlign: "center", marginLeft:"20px" }}
                    >
                      <Typography variant="body2">
                        <a href={getSelfcareUrl(window.location.host)}>Log In</a>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
            </Container>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* Offset for content */}
    </>
  );
};

const HeroSection = () => (
  <>
    <Box
      className="hero-section"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
        className="yeah-girl-img"
      >
        <Typography className="hero-desc" variant="h4" gutterBottom>
          <span>Lightning-fast internet.</span> <span>Crazy low price.</span>
        </Typography>
      </Box>
    </Box>
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        my: 5,
      }}
    >
      <Link to="/check-availability" className="avail-btn large">
        Check availability on your street
        <img src={magnifyLens} className="magnify-lens" />
      </Link>
    </Container>
  </>
);

// PlansSection component
const PlansSection = () => (
  // Use Grid for layout of plans

  <Box className="plan-section">
    <Container>
      <Typography className="title">
      Introducing the amazing new internet service exclusively for Fort Carson Family Housing!
      </Typography>
      <Typography className="sub-title">
      Boingo Fiber is designed for military families like yours, who do so much for our country. It’s lightning-fast and sensibly priced &mdash; and since you use your own router, there are no equipment fees.
      </Typography>
      <Typography className="sub-title">
        It&#8217;s the internet you deserve. <span>#YEAH!</span>
      </Typography>
    </Container>
  </Box>
);

// FeaturesSection component
const FeaturesSection = () => {
  const [isOpen1Gig, setIsOpen1Gig] = useState(false);
  const [isOpen2Gigs, setIsOpen2Gigs] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onClickPlan1GigPlan = () => {
    setIsOpen1Gig(!isOpen1Gig);
  };
  const onClickPlan2GigPlan = () => {
    setIsOpen2Gigs(!isOpen2Gigs);
  };
  return (
    <>
      <Box className="feature-section">
        <Container>
          <Box className="plan-container">
            <Box className={`plan-wrap ${isOpen1Gig ? "expanded" : ""}`}>
              <Typography className="plan-title">1 gigabit</Typography>
              <Box className="description-wrap">
                <Box className="desc-row left">
                  <Typography className="description">
                    Fast, reliable internet for your whole home.
                  </Typography>
                </Box>
                <Box className="desc-row right">
                  <Typography className="price">
                    <span className="dollar-sign">$</span>
                    69
                    <span className="duration">
                      {isMobile ? "/month" : "/mo"}
                    </span>
                  </Typography>
                </Box>
                <Box
                  className="details-wrap"
                  onClick={onClickPlan1GigPlan}
                  sx={{ cursor: "pointer" }}
                >
                  <ExpandMoreIcon
                    className={isOpen1Gig ? "up-arrow" : ""}
                    sx={{
                      verticalAlign: "middle",
                      color: "#000",
                    }}
                  />
                  Plan details
                </Box>
                {isOpen1Gig && (
                  <>
                    <Box>
                      <ul className="plan-details-list">
                        {[
                          "Symmetrical download and upload speeds up to 1 gigabit",
                          "No data caps or throttling",
                          "Use your own equipment",
                          "Instant on",
                        ].map((text, index) => (
                          <li key={index}>{text}</li>
                        ))}
                      </ul>
                      <Link
                        to="/check-availability"
                        className="get-started-btn"
                      >
                        Get started
                      </Link>
                    </Box>
                  </>
                )}
              </Box>
            </Box>

            <Box
              className={`plan-wrap ${isOpen2Gigs ? "expanded" : ""
                } two-gigabits`}
            >
              <Typography className="plan-title">2 gigabits</Typography>
              <Box className="description-wrap">
                <Box className="coming-soon-ribbon"></Box>
                <Box className="desc-row left">
                  <Typography className="description">
                    Great for next-gen gaming and streaming.
                  </Typography>
                </Box>
                <Box className="desc-row right">
                  <Typography className="price">
                    <span className="dollar-sign">$</span>
                    99
                    <span className="duration">
                      {isMobile ? "/month" : "/mo"}
                    </span>
                  </Typography>
                </Box>
                <Box
                  className="details-wrap"
                  onClick={onClickPlan2GigPlan}
                  sx={{ cursor: "pointer" }}
                >
                  <ExpandMoreIcon
                    className={isOpen2Gigs ? "up-arrow" : ""}
                    sx={{ verticalAlign: "middle", color: "#000" }}
                  />
                  Plan details
                </Box>
                {isOpen2Gigs && (
                  <>
                    <Box sx={{ textAlign: "left" }}>
                      <ul className="plan-details-list">
                        {[
                          "Symmetrical download and upload speeds up to 2 gigabits",
                          "No data caps or throttling",
                          "Use your own equipment",
                          "Instant on",
                        ].map((text, index) => (
                          <li key={index}>{text}</li>
                        ))}
                      </ul>
                      <Link className="get-started-btn disabled">
                        Coming soon
                      </Link>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>

          <img
            src={whyBoingoFiberSubHeader}
            className="why-boingo-fiber-img"
            width="350px"
          />

          <Box className="why-fiber-container">
            <Box className="why-fiber-box">
              <Box className="fiber-icon speed"></Box>
              <Typography className="why-fiber-title">Speed</Typography>
              <Typography className="why-fiber-desc">
                Download and upload speeds up to 1 gigabit!
              </Typography>
            </Box>
            <Box className="why-fiber-box">
              <Box className="fiber-icon instant"></Box>

              <Typography className="why-fiber-title">Instant on</Typography>
              <Typography className="why-fiber-desc">
                No waiting for the cable guy. Or the cable gal.{" "}
              </Typography>
            </Box>
            <Box className="why-fiber-box">
              <Box className="fiber-icon no-equip"></Box>
              <Typography className="why-fiber-title">
                No equipment charges
              </Typography>
              <Typography className="why-fiber-desc">
                Our BYO plan means you use your own equipment.{" "}
              </Typography>
              <Typography className="learn-more">
                <HashLink
                  to="#routerSection"
                  className="learn-more"
                  scroll={(el) => {
                    const offsetAmt = isMobile ? 0 : 50;
                    const desiredOffset =
                      el.getBoundingClientRect().top +
                      window.pageYOffset -
                      offsetAmt;
                    window.scrollTo({ top: desiredOffset, behavior: "smooth" });
                  }}
                >
                  Learn more about equipment!
                </HashLink>
              </Typography>
            </Box>

            <Box className="why-fiber-box">
              <Box className="fiber-icon data-caps"></Box>

              <Typography className="why-fiber-title">No data caps</Typography>
              <Typography className="why-fiber-desc">
              No data caps, no throttling, and no device limits.
              </Typography>
            </Box>
            <Box className="why-fiber-box">
              <Box className="fiber-icon heart"></Box>
              <Typography className="why-fiber-title">Customer care</Typography>
              <Typography className="why-fiber-desc">
                Award-winning care from friendly humans 24/7/365.
              </Typography>
            </Box>
            <Box className="why-fiber-box">
              <Box className="fiber-icon we-get-you"></Box>

              <Typography className="why-fiber-title">We get you</Typography>
              <Typography className="why-fiber-desc">
                We understand military families as many of our team are veterans
                or married to active-duty personnel.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

// RouterSpecsSection component
const RouterSpecsSection = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handlePopOverClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box className="router-section" id="routerSection">
        <Container>
          <Box id="SetupInSeconds" className="router-header-img">
            <img src={setUpSubHeader} />
          </Box>

          <Typography className="router-desc">
            Cable companies charge big monthly fees for their equipment. Boingo
            Fiber is different. Our &#8220;BYO&#8221; plan means you use your
            own equipment, which saves you a bundle.
          </Typography>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPopover-paper": {
                position: "relative",
                width: "25%", // Default width for larger screens
                "@media (max-width: 767px)": {
                  width: "100%", // Width for screens less than 767px
                },
                marginTop: "10px",
                paddingBottom: "15px",
                overflow: "visible",
                "&:before": {
                  content: '""',
                  position: "absolute",
                  zIndex: "10",
                  top: -16,
                  left: 20,
                  "@media(max-width: 767px)": {
                    left: 240,
                  },
                  borderBottom: "8px solid",
                  borderBottomColor: "#fff",
                  borderLeft: "8px solid transparent",
                  borderRight: "8px solid transparent",
                  borderTop: "8px solid transparent",
                },
              },
            }}
          >
            <Typography
              className="popover-title"
              sx={{
                p: 2,
                fontFamily: "Figtree-Bold",
                lineHeight: "1.2",
                color: "#000",
              }}
            >
              Make sure your equipment meets the following specs:
            </Typography>
            <List sx={{ listStyleType: "disc", margin: "-10px 0 0px 14px" }}>
              {[
                "\u2022 Supports gigabit wired speeds",
                "\u2022 Wi-Fi 5 (802.11ac) or Wi-Fi 6 (802.11ax)",
                "\u2022 80Mhz channel width",
                "\u2022 Minimum WPA2 Security",
              ].map((text, index) => (
                <ListItemText primary={text} key={index} />
              ))}
            </List>
          </Popover>

          <Typography className="router-desc">
            <span>Need equipment?</span>
            We recommend a Whole Home Wi-Fi system.{!isMobile ? (
              <br />
            ) : (
              ""
            )}{" "}
            You&#8217;re welcome to use any system {isMobile ? <br /> : ""} that
            meets{" "}
            <a
              href=""
              aria-describedby={id}
              variant="contained"
              onClick={handlePopOverClick}
            >
              these specs.
            </a>
          </Typography>
          <Box className="router-img-section">
            <Box className="router-img-wrapper">
              <img src={tpLinkImg} />
              <Typography className="router-name">
                TP-Link Deco W3600
              </Typography>
            </Box>
            <Box className="router-img-wrapper">
              <img src={amazonEeroImg} />
              <Typography className="router-name">Amazon Eero 6</Typography>
            </Box>
            <Box className="router-img-wrapper">
              <img src={dLinkImg} />
              <Typography className="router-name">D-Link AX1500</Typography>
            </Box>
            <Box className="router-img-wrapper">
              <img src={googleACImg} />
              <Typography className="router-name">Google AC1200</Typography>
            </Box>
          </Box>
          <Typography className="router-desc last-paragraph">
            We have stress tested these systems in our labs. You can purchase
            them online or at The Exchange. Look for the &#8216;&#8216;Works
            with Boingo Fiber&#8221; sticker!
          </Typography>
          <Box className="router-bottom-section">
            <img src={worksWithBoingImg} className="works-with-boingo-img" />
            <img src={lookForSticker} className="look-for-this" />
          </Box>
        </Container>
      </Box>
    </>
  );
};

// Footer component
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Box className="landing-page-footer-section">
        <Container>
          <Typography className="footer-title">
            Let&#8217;s start by finding out if Boingo Fiber is available in
            your home
          </Typography>
          <Link to="/check-availability" className="avail-btn">
            Check availability
          </Link>
          <Box>
            <img src={horizontalLine} className="horizontal-line" />
            <img src={boingoLogo} className="footer-logo" />
          </Box>
          <Box>
            <Box className="footer-links-wrapper">
              <Link
                to="https://www.boingo.com/legal/boingo-privacy-policy/"
                target="_blank"
              >
                Privacy policy
              </Link>{" "}
              &nbsp;|&nbsp;&nbsp;
              <Link
                target="_blank"
                to="https://www.boingo.com/legal/website-terms-use-acceptable-use-policy/"
              >
                Terms of use
              </Link>{" "}
              &nbsp;|&nbsp;&nbsp;
              <Link
                target="_blank"
                to="https://www.boingo.com/legal/cookie-policy/"
              >
                Cookie policy
              </Link>
            </Box>
            <Box className="footer-links-wrapper">
              <Typography className="copyright">
                &copy; {currentYear} Boingo Wireless, Inc. All rights reserved.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const FixedCheckAvailability = () => {
  return (
    <>
      <Box className="fixed-check-avail">
        <Link to="/check-availability">Check availability</Link>
      </Box>
    </>
  );
};

const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showFixedFooter, setShowFixedFooter] = useState(false);
  const [isNavHidden, setIsNavHidden] = useState(false);

  const handleScroll = () => {
    const bottomReached =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - 300;

    if (window.scrollY > 100 && !bottomReached) {
      setShowFixedFooter(true);
      setIsNavHidden(true);
    } else {
      setShowFixedFooter(false);
      setIsNavHidden(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("scroll", handleScroll);
    } else {
      setShowFixedFooter(false);
      setIsNavHidden(false);
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isMobile]);

  return (
    <Box sx={{ textAlign: "center" }}>
      {!isNavHidden && <Navbar />}
      <HeroSection />
      <PlansSection />
      <FeaturesSection />
      <RouterSpecsSection />
      <Footer />
      {isMobile && showFixedFooter && <FixedCheckAvailability />}
    </Box>
  );
};

export default LandingPage;
